@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
    line-height: 1.5;
    max-height: 100vh;
}

.logo {
    display: block;
    margin: 0 auto 2rem;
}

nav {
    width: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 2rem;
}

nav a.router-link-exact-active {
    color: var(--color-text);
}

nav a.router-link-exact-active:hover {
    background-color: transparent;
}

nav a {
    display: inline-block;
    padding: 0 1rem;
    border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
    border: 0;
}

@media (min-width: 1024px) {
    header {
        display: flex;
        place-items: center;
        padding-right: calc(var(--section-gap) / 2);
    }

    .logo {
        margin: 0 2rem 0 0;
    }

    header .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    nav {
        text-align: left;
        font-size: 1rem;

        padding: 1rem 0;
        margin-top: 1rem;
    }
}

.blog-post {
    color: #444;
    line-height: 1.6;
    max-width: 700px;
    margin: 0 auto;
    padding: 1em;
}

.blog-post h1, .blog-post h2, .blog-post h3, .blog-post h4, .blog-post h5, .blog-post h6 {
    font-weight: 700;
    color: #222;
    line-height: 1.3;
}

.blog-post h1 {
    font-size: 2.2em;
}

.blog-post h2 {
    font-size: 1.8em;
}

.blog-post h3 {
    font-size: 1.5em;
}

.blog-post h4, .blog-post h5, .blog-post h6 {
    font-size: 1.3em;
}

.blog-post p, .blog-post ul, .blog-post ol {
    margin-bottom: 1.5em;
}

.blog-post a {
    color: #1e6b9a;
}

.blog-post a:hover {
    color: #0d3c55;
}

.blog-post pre {
    background-color: #f4f4f4;
    padding: 1em;
    border-radius: 3px;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace;
    line-height: 1.5;
}

.blog-post code {
    font-family: 'Courier New', Courier, monospace;
    color: #333;
    background-color: #f4f4f4;
    padding: 0.2em 0.4em;
    border-radius: 3px;
}
